import React from "react";
import {ICamera, ISketchfabViewerApi} from "./SketchfabViewer";
import {Box, Button, Paper, Typography, useTheme} from "@mui/material";
import styles from './Cameras.module.css'

interface ICamerasProps{
  viewerapi: ISketchfabViewerApi;
  cameras: ICamera[];
  moveCamera(camera: ICamera, viewerapi: ISketchfabViewerApi): void;
}

function Cameras(props: ICamerasProps){
  const {cameras, moveCamera, viewerapi} = props;

  return <div className={styles.cameraContainer}>
    <Paper sx={{padding: "10px"}} elevation={4}>
    <div className={styles.cameraHeader}>
      <Typography variant={"h5"}>Cameras</Typography>
    </div>
    <div className={styles.camerasList}>
      {
        cameras.map((camera: ICamera) => {
          return <div className={styles.cameraItem}>
            <Button variant={"contained"} color={"primary"} key={camera.name} value={camera.name}
                           onClick={() => moveCamera(camera, viewerapi)}>{camera.name}</Button>
          </div>
        })
      }
    </div>
    </Paper>
  </div>
}

export default Cameras;