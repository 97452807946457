import React, {useEffect, useState} from "react";
import './OptionSelector.css';
import {ChangeCommand, IMapTableRecord, IModel} from "./App";
import {Client, JSGetOptContext} from "OptConfigurator";
import OptionList from "./OptionList";
import {KovaConfigurator} from "@bimaire/optionslib-kova";
import {IConfiguratorProxy, IMenuWithItems, IOptionItemAvailableChoice, IOptionItemChoice} from "@bimaire/optionslib";
import {Drawer} from "@mui/material";
import {OptionItemMenu} from "@bimaire/react-lib";
import {IOptSelInfo} from "@bimaire/optionslib-kova/build/loaders/optSelInfo";
import {OptEngineWrapper} from "@bimaire/optengine";
import {IStringVector} from "@bimaire/optengine/build/optengine_loader";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {IOptionChoiceAvailabilityMap} from "@bimaire/optionslib/build/types";

function isChoiceAvailable(availability: IOptionChoiceAvailabilityMap["availability"], choice: IOptionItemAvailableChoice) {
    return availability.get(choice)?.isVisible && availability.get(choice)?.isEnabled;
}

interface IOptionSelectorProps {

    applyOptions(options : IOptionItemChoice[]) : void;
    model: IModel;
    optionEngine: OptEngineWrapper;
    mapTable: IMapTableRecord[];
}

export interface ISelectedOption{
    optSelID: string;
    optValID: string;
}

function OptionSelector(props: IOptionSelectorProps) {

    const { applyOptions, model, optionEngine, mapTable } = props;

    const [currentConfigurator, setCurrentConfigurator] = useState<IConfiguratorProxy | null>(null);
    const [currentMenu, setCurrentMenu] = useState<IMenuWithItems | null>(null);
    const [relevantOptSelIDs, setRelevantOptSelIDs] = useState<string[] | null>(null);


    const handleMenuItemClick = (choice: IOptionItemAvailableChoice) => {
        if (currentConfigurator === null) return;
        let conf = currentConfigurator.applyChoice({price: null, quantity: 1, choice: choice});
        setCurrentConfigurator(conf);

        let selectedChoices = conf.getChoices();
        applyOptions(selectedChoices);
    }

    /*
    const handleMenuItemClick = (menu: IMenuWithItems) => {
        setCurrentMenu(menu);
        //TODO menu is optval, there might be several choices
        const choice = currentConfigurator!.getAllChoices().find(ch => ch.items.findIndex(i => i.item.id === menu.id) >= 0);
        if (choice !== undefined) {
            let conf = currentConfigurator!.applyChoice({price: null, quantity: 1, choice: choice});
            setCurrentConfigurator(conf);

            let selectedChoices = conf.getChoices();
            applyOptions(selectedChoices);
        }
    };*/



    useEffect(() => {
        const loadOptContext = async () => {
            const data = await fetch(model.optContextPath);
            const respJson = await data.json();
            const state = respJson.source ? Client.loadFromJSON(respJson.source as JSGetOptContext) :  Client.loadFromJSON(respJson as JSGetOptContext);

            //get all optsels from conditions and hide other options
            let conditionContext = optionEngine.getJsOptContext();
            let optSelIDs = [];
            for(let mapTableRecord of mapTable){
                let jsConditionString = conditionContext.addCondition(mapTableRecord.OptionString);
                let tokens: IStringVector = jsConditionString.getOptionIdentifiers();
                for(let i = 0; i < tokens.size(); i++){
                    optSelIDs.push(tokens.get(i));
                }
            }

            let uniqueOptSelIDs = optSelIDs.filter((v, i, a) => a.indexOf(v) === i);
            //let optSelInfo = uniqueOptSelIDs.map(uniqueOptSelID => {return {id: uniqueOptSelID}}) as IOptSelInfo[];

            setRelevantOptSelIDs(uniqueOptSelIDs);

            const kovaConfigurator = new KovaConfigurator(null);

            let conf = kovaConfigurator.getConfiguratorProxy(state) as IConfiguratorProxy;

            /*
            always set ALWAYS option selected?
            const choice = conf.getAllChoices().find(ch => ch.items.findIndex(i => i.item.id === "ALWAYS") >= 0);
            if (choice !== undefined) {
                conf = conf.applyChoice({price: null, quantity: 1, choice: choice});

            }*/


            setCurrentConfigurator(conf);
            //apply defaults
            applyOptions(conf.getChoices());
        }

        loadOptContext();
    }, [])


    if (!currentConfigurator) return <div>Loading...</div>;

    const availableChoices = currentConfigurator.getAllChoices();
    const avail = currentConfigurator.getOptionAvailability(availableChoices);

    let visibleChoices = availableChoices.filter(ch => isChoiceAvailable(avail.availability, ch) && (ch.items[0].item.categories["DesignCenter"] !== "yes" || relevantOptSelIDs?.includes(ch.items[0].item.id)));

    const visibleItems = visibleChoices.map(ch => ch.items[0].item).filter((item, index, array) => array.indexOf(item) === index);
    const itemsBySelectionId = groupBy(visibleChoices, ch => ch.items[0].item.id);
    const menuOptSels = visibleItems.map(i => i.id);
    const selectedChoices = currentConfigurator.getChoices().filter(ch => menuOptSels.includes(ch.choice.items[0].item.id));
    /*const visibleChoices = availableChoices.filter(ch =>
      isChoiceAvailable(avail.availability, ch) &&
      ch.items[0].item.categories["SalesOffice"] === "yes" &&
      ch.items.length > 1 && priceSource(ch) !== null
    );*/
    //const menuOptSels = menus.map(m => m.id);
    //var selectedChoices = currentConfigurator.getChoices().filter(ch => menuOptSels.includes(ch.choice.items[0].item.id));

    return (
        <div className="OptionSelector">

            <Drawer

                sx={{
                    width: 400,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 400,
                        boxSizing: 'border-box',
                        background: '#F5F5F5'
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <OptionItemMenu items={visibleItems} itemsBySelectionId={itemsBySelectionId} selectedChoices={selectedChoices} handleSelection={handleMenuItemClick}  />
            </Drawer>
        </div>
    );
}

export default OptionSelector;