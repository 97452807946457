import {ConstructNameToInstanceID} from "./SketchFabViewerUtils";
import React, {useState} from "react";
import {ISketchfabViewerApi} from "./SketchfabViewer";
import styles from "./ConstructSelector.module.css";
import {Box, Checkbox, FormControlLabel, Paper, toggleButtonClasses, Typography} from "@mui/material";


interface IConstructSelectorProps{
  constructToInstanceIDMap: any;
  constructVisibilities: Map<string, boolean>;
  changeConstructVisibility(constructId: ConstructNameToInstanceID): void;
  toggleAllConstructs(show: boolean): void;


}

function ConstructSelector(props: IConstructSelectorProps){
  const {constructToInstanceIDMap, constructVisibilities, changeConstructVisibility, toggleAllConstructs} = props;

  const allConstructsVisible = !Array.from(constructVisibilities.values()).includes(false);

  return <Paper sx={{padding: "10px"}} elevation={4}>
  <div className={styles.constructs}>
    <Box style={{display: "flex", justifyContent: "space-between"}}>
    <Typography variant={"h5"}>Constructs</Typography>
      <FormControlLabel control={
        <Checkbox checked={allConstructsVisible}  onChange={(e) => {
          toggleAllConstructs(!allConstructsVisible);
        }}></Checkbox>
      } label={"Toggle All"} />
    </Box>


    <div className={styles.constructsList}>
      {
        constructToInstanceIDMap && constructVisibilities && constructToInstanceIDMap.map((constructToId: ConstructNameToInstanceID) => {
          return <div className={styles.constructItem}>
            <FormControlLabel control={
              <Checkbox checked={constructVisibilities.get(constructToId.name)} onChange={() => changeConstructVisibility(constructToId)}></Checkbox>
            } label={constructToId.name} />
          </div>
        })
      }
    </div>
  </div>
  </Paper>
}

export default ConstructSelector;