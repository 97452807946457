import {SingleView, ViewMetaData} from "./SketchFabViewerUtils";
import React, {useState} from "react";
import {inspect} from "util";
import styles from './ViewSelector.module.css'
import {Box, Button, Checkbox, FormControlLabel, Input, Paper, styled, Typography} from "@mui/material";
import {UploadFile} from "@mui/icons-material";


const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;


interface IViewsProps{
  applyView(view: SingleView) : void;
  highlightModeOn: boolean;
  setHighlightModeOn: React.Dispatch<React.SetStateAction<boolean>>;
  highlightModeRef: React.MutableRefObject<boolean>;

}

function ViewSelector(props: IViewsProps) {
  const [viewMetaData, setViewMetaData] = useState<ViewMetaData | undefined>(undefined);
  const {applyView, highlightModeOn, setHighlightModeOn, highlightModeRef} = props;

  const onViewDataFileChanged = (event: any) => {
    if(event.target.files[0])
    {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");

      fileReader.onload = function(event) {
        if(event?.target?.result){
          let viewMetaData = JSON.parse(event.target.result as string) as ViewMetaData;
          setViewMetaData(viewMetaData);
        }
      };
    }
  };


  return <div className={styles.views}>
    <Paper sx={{padding: "10px"}} elevation={4}>
        <Box style={{display: "flex", justifyContent: "space-between"}}>
          <Typography variant={"h5"}>Views</Typography>

          <div>

            <FormControlLabel control={
              <Checkbox checked={highlightModeOn}  onChange={(e) => {
                setHighlightModeOn(e.target.checked);
                highlightModeRef.current = e.target.checked
              }}></Checkbox>
            } label={"Object Highlighting On"} />

        </div>

      </Box>

          <div className={styles.viewList}>
            {
              viewMetaData?.views.map(view => {
                return <div className={styles.viewItem}>
                    <Button color={"primary"} variant={"contained"}  onClick={() => applyView(view)}>{view.viewName}</Button>
                </div>
              })
            }
            <div className={styles.viewsUpload}>
              <Button
                component="label"
                variant="contained"
                startIcon={<UploadFile />}
                href="#file-upload"
              >
                Views JSON
                <VisuallyHiddenInput type="file" onChange={onViewDataFileChanged}/>
              </Button>
            </div>
          </div>
    </Paper>
        </div>

}

export default ViewSelector;