import validator from 'validator';
import {INode, ISketchfabViewerApi} from "./SketchfabViewer";
import {IMapTableRecord} from "./App";


export interface ViewMetaData
{
    views: SingleView[];
}
export interface SingleView{
    viewName: string;
    constructs: string[];
}

export class ConstructNameToInstanceID{
    constructor(name: string, id:number) {
        this.name = name;
        this.id = id;
    }
    name: string;
    id: number

}

class SketchFabViewerUtils{




    static GetGuidToInstanceIDMap(nodeMap : any) : {guidToInstanceIDMap : Map<string, number>,  constructsToInstanceIDMap: ConstructNameToInstanceID[]} {
        let guidToObjectIDMap = new Map<string, number>();
        let constructToInstanceIDMap : ConstructNameToInstanceID[] = [];

        Object.entries(nodeMap).forEach(([key, value]) => {

            let node = value as INode;
            if(node.name && node.name.startsWith("dwg__")){
                constructToInstanceIDMap.push(new ConstructNameToInstanceID(node.name.replace('dwg__', '').replaceAll('_', ' '), node.instanceID));
            }
            else if(node.name && node.name.startsWith("optid__")){
                guidToObjectIDMap.set(node.name, node.instanceID);
            }
            /*else if(node.name && node.name.length > 1 && validator.isUUID("1" + node.name.replaceAll("_", "-").substring(1))){
                guidToObjectIDMap.set("1" + node.name.replaceAll("_", "-").substring(1), node.instanceID);
            }*/
        })
        return {guidToInstanceIDMap: guidToObjectIDMap, constructsToInstanceIDMap: constructToInstanceIDMap} ;
    }


    static getMaterialsStr(materials : any){
        let materialJSON = materials.map((material : any) => {
            return {id: material.id, textureUID: material.channels.AlbedoPBR.texture ? material.channels.AlbedoPBR.texture.uid : "N/A"}
        })
        return JSON.stringify(materialJSON);
    }

    static getTexturesStr(textures : any){
        let textureJSON = textures.map((texture : any) => {
            return {name: texture.name, uid: texture.uid}
        })
        return JSON.stringify(textureJSON);
    }

    static getNodeMapStr(nodeMap: any[]){
        let nodes : any[] = [];
        Object.keys(nodeMap).forEach((key : any) =>{
            nodes.push({name: nodeMap[key].name, instanceID: nodeMap[key].instanceID});
        })
        return JSON.stringify(nodes);
    }

    static showOrHideObjcects(objectIds: number[], api : ISketchfabViewerApi |undefined, isShow: boolean){
        if(!api){
            return;
        }
        objectIds.forEach((objectId: number) =>
            {
                isShow ? api.show(objectId) : api.hide(objectId);
            }
        )
    }


    static findPathToNode(node: any, instanceId: string, path: string[]) : boolean
    {
        if(!node){
            return false;
        }

        if(node.instanceID == instanceId){
            path.push(node.name);
            return true;
        }

        if(!node.children){
            return false;
        }

        for(let child of node.children) {
            if(this.findPathToNode(child, instanceId, path)){
                path.push(child.name);
                return true;
            }
        }
        return false;
    }
}

export {SketchFabViewerUtils}
