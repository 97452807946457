import {ICamera, ISketchfabViewerApi} from "./SketchfabViewer";
import {IModel} from "./App";


class ViewerInitializer {

  constructor(model: IModel,
  viewerIframeRef: React.MutableRefObject<any>,
  graph: React.MutableRefObject<any>,
  setNodeMap: React.Dispatch<React.SetStateAction<any>>,
  setTextures: React.Dispatch<React.SetStateAction<any>>,
  setMaterials: React.Dispatch<React.SetStateAction<any>>,
  viewerapi: React.MutableRefObject<ISketchfabViewerApi | undefined>,
  showClickedConstruct: (instenceId: string) => void,
  nodeMouseEnter: (node: any) => void,
  moveCamera: (camera: ICamera, api: ISketchfabViewerApi | undefined) => void) {
    this.model = model;
    this.viewerIframeRef = viewerIframeRef;
    this.graph = graph;
    this.setNodeMap = setNodeMap;
    this.setTextures = setTextures;
    this.setMaterials = setMaterials;
    this.viewerapi = viewerapi;
    this.showClickedConstruct = showClickedConstruct;
    this.nodeMouseEnter = nodeMouseEnter;
    this.moveCamera = moveCamera;
  }

  model: IModel;
  viewerIframeRef: React.MutableRefObject<any>;
  graph: React.MutableRefObject<any>;
  setNodeMap: React.Dispatch<React.SetStateAction<any>>;
  setTextures: React.Dispatch<React.SetStateAction<any>>;
  setMaterials: React.Dispatch<React.SetStateAction<any>>;
  viewerapi: React.MutableRefObject<ISketchfabViewerApi | undefined>;
  showClickedConstruct: (instenceId: string) => void;
  nodeMouseEnter: (node: any) => void;
  moveCamera: (camera: ICamera, api: ISketchfabViewerApi | undefined) => void;


  initializeSketchfabViewer() {
    let client = new (window as any).Sketchfab(this.viewerIframeRef.current);

    client.init(this.model.sketchfabModelId, {
      success: (api: ISketchfabViewerApi) =>  {

        api.start();
        api.addEventListener('viewerready', () => {

          api.getSceneGraph((err: any, result: any) => {
            if (err) {
              console.log('Error getting nodes');
              return;
            }

            this.graph.current = result;
            console.log(result);
          });

          api.getNodeMap((err: any, result: any) => {
            if (err) {
              console.log('Error getting nodes');
              return;
            }
            this.setNodeMap(result);
            console.log(result);
          });

          api.getTextureList((err: any, textures: any) => {
            if (!err) {
              window.console.log(textures);
              this.setTextures(textures);
            }
          });

          api.getMaterialList((err: any, materials: any) => {
            if (!err) {
              window.console.log(materials);
              this.setMaterials(materials);
            }
          });


          api.addEventListener(
            'click',
            (info: any) => {
              window.console.log('click at', info.position2D);
              if (info.instanceID) {
                this.showClickedConstruct(info.instanceID);
              }
            }
          );
          api.addEventListener(
            'nodeMouseEnter',
            (node: any) => {
              this.nodeMouseEnter(node);
            }
          );

          api.setHighlightOptions({
            outlineWidth: 5,
            outlineColor: [1.0, 0.0, 0.0],
            outlineDuration: 2,
            highlightColor: [0.0, 0.0, 1.0],
            highlightDuration: 1
          });

          // API is ready to use
          // Insert your code here
          console.log('Viewer is ready');
          let frontCamera = this.model.cameras.find(c => c.name === "Front");
          if (frontCamera) {
            this.moveCamera(frontCamera, api);
          }
          (window as any).sketsfap = api;

          this.viewerapi.current = api;


        });
      },
      error: function onError() {
        alert('error');
        console.log('Viewer error');
      }
    });
  }
}

export default ViewerInitializer;