import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import OptionSelector, {ISelectedOption} from './OptionSelector'
import SketchfabViewer, {ICamera} from './SketchfabViewer'
import {XMLParser} from "fast-xml-parser";
import {OptionEvaluator} from "./OptionEvaluator";
import {IOptionItemChoice} from "@bimaire/optionslib";
import { OptEngineWrapper} from "@bimaire/optengine";
import { useSearchParams } from "react-router-dom";

export class ChangeCommand {
    objectGUID: string;
    isShow: boolean;
    textureUID: string;
    sketchfabInstanceID: number | undefined;
    constructor(objectGUID : string, isShow : boolean, textureUID : string, sketchfabInstanceID?: number ) {
        this.objectGUID = objectGUID;
        this.isShow = isShow;
        this.textureUID = textureUID;
        this.sketchfabInstanceID = sketchfabInstanceID;
    }
}

export interface IMapTableRecord{
    BoxGuid: string;
    OptionString: string; //rule string
    FullPath: string;
}

export interface IModel{
    name: string;
    sketchfabModelId: string;
    optContextPath: string;
    cameras: ICamera[];
    guidToOptionRuleMappingPath: string;
}

export interface IModelMetaDataResponse{
    sketchfabModelId: string;
    cameras: ICamera[];
    optContextPath: string;
    virtualizationMapPath: string;
}

function getDefaultCameras() : ICamera[]{

    let cameras : ICamera[] = [];
    cameras.push({
        "name": "Top",
        "origin": [0, 0, 30],
        "target": [0, 0, 0]
    });

    cameras.push({
        "name": "Front",
        "origin" : [30,0,5],
        "target" : [0,0,5]
    });

    cameras.push({
        "name": "Back",
        "origin": [-30, 0, 5],
        "target": [0, 0, 5]
    });

    return cameras;

}

async function loadModelMetaData(env: string, modelid: string, version: string) : Promise<IModel>{
    //const response = await fetch(`https://localhost:7141/api/${env}/Viewer3D/Get3DViewerData?modelid=${modelid}&version=${version}`);
    const response = await fetch(`/api/${env}/Viewer3D/Get3DViewerData?modelid=${modelid}&version=${version}`);
    const json = await response.json() as IModelMetaDataResponse;
    let model = {} as IModel;
    model.name = modelid;
    model.sketchfabModelId = json.sketchfabModelId;
    model.optContextPath = json.optContextPath;
    model.cameras = json.cameras && json.cameras.length > 0 ? json.cameras : getDefaultCameras();
    model.guidToOptionRuleMappingPath = json.virtualizationMapPath;
    return model;

}

async function loadModels(){
    const response = await fetch('./models.json');
    const json = await response.json();
    return json as IModel[];
}

async function loadOptionRulesXML(model: IModel){
    const xmlContent = await fetch(model.guidToOptionRuleMappingPath);
    let parser = new XMLParser();
    let text = await xmlContent.text();
    let parsedXML = parser.parse(text);
    console.log(parsedXML.root.MapTable);
    return parsedXML.root.MapTable as IMapTableRecord[];
}


function useOptionEvaluator(optionEngine: OptEngineWrapper, mapTable: IMapTableRecord[] | undefined) {
    return useMemo(() => {
        if (mapTable === undefined) return undefined;
        console.log('Initializing option evaluator');
        const optionEvaluator = new OptionEvaluator(optionEngine, mapTable);
        return optionEvaluator;

    }, [mapTable])
}

function App(props: { optionEngine: OptEngineWrapper}) {

    const [changeCommands, setChangeCommands] = useState<ChangeCommand[] | undefined>(undefined);
    const [mapTable, setMapTable] = useState<IMapTableRecord[] | undefined>(undefined);
    const [models, setModels] = useState<IModel[] | undefined>(undefined);
    const [model, setModel] = useState<IModel | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();


    const optionEvaluator = useOptionEvaluator(props.optionEngine, mapTable);


    const selectModel = (model: IModel) => {
        setModel(model);
    }

    useEffect(() => {
        (window as any).allowMissingPrice = true;
        console.log("Loading model data");
        //if model is defined in the query params, get data from kovaapi, else show contents from models.json
        let env = searchParams.get("env");
        let modelid = searchParams.get("modelid");
        let version = searchParams.get("version");
        if(env && modelid && version){
            loadModelMetaData(env, modelid, version).then(response => {
                setModel(response);
            });
        }
        else {
            loadModels().then((response) => {
                setModels(response);
            });
        }
    }, []);

    useEffect(() => {
        if(model) {
            loadOptionRulesXML(model).then((response) => {
                setMapTable(response);
            });
        }
    }, [model]);

    const applyOptions = (selectedChoices : IOptionItemChoice[]) : void => {
        if(optionEvaluator) {
            let selectedOptions: ISelectedOption[] = [];
            selectedChoices.forEach((choice: IOptionItemChoice) => {
                let optSelID = choice.choice.items[0] && choice.choice.items[0].item ? choice.choice.items[0].item.id : "";
                let optValID = choice.choice.items[1] && choice.choice.items[1].item ? choice.choice.items[1].item.id : "";
                selectedOptions.push({optSelID: optSelID, optValID: optValID} as ISelectedOption)
            })


            let commands = optionEvaluator.getChangeCommands(selectedOptions);
            setChangeCommands(commands);
        }
    }


    return (
        <div className="App">
          <header className="App-header">
              {!model && models &&
                  <div>
                      <div>
                        Select model
                      </div>
                      <div>
                          {models.map((model: IModel) => {
                              return <button onClick={() => selectModel(model)}>{model.name}</button>
                            })
                          }
                      </div>
                  </div>
              }
              {model &&
                  <div className={"AppContainer"}>
                      {mapTable && <OptionSelector applyOptions={applyOptions} model={model} optionEngine={props.optionEngine} mapTable={mapTable}/>}
                      {changeCommands && <SketchfabViewer changeCommands={changeCommands} model={model} mapTable={mapTable}/>}
                  </div>
              }

          </header>
        </div>
    );
}

export default App;
