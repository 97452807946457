import {SketchFabViewerUtils} from "./SketchFabViewerUtils";
import React, {useState} from "react";
import {ChangeCommand} from "./App";
import {ISketchfabViewerApi} from "./SketchfabViewer";


interface IDebugToolsProps{
  viewerapi: ISketchfabViewerApi;
  ApplyCommand(changeCommand: ChangeCommand, api: any, nodeMap: any, guidToObjectIDMap : Map<string, number>): void;
  materials: any;
  textures: any;
  nodeMap: any;
  guidToObjectIDMap: Map<string, number>;
}

function DebugTools(props: IDebugToolsProps){

  const {viewerapi, ApplyCommand, materials, textures, nodeMap, guidToObjectIDMap} = props;

  const [objectGUID, setObjectGUID] = useState<string>("");
  const [sketchfabInstanceID, setSketchfabInstanceID] = useState<number | undefined>(undefined);
  const [materialUID, setMaterialUID] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(true);

  const applyClick = () => {
    ApplyCommand(new ChangeCommand(objectGUID, isShow, materialUID, sketchfabInstanceID), viewerapi, nodeMap, guidToObjectIDMap!);
  }


  const groupIdChange = (event : any) => {
    setObjectGUID(event.target.value);
  }
  const materialUIDChange = (event : any) => {
    setMaterialUID(event.target.value);
  }
  const sketchfabInstanceIDChange = (event : any) => {
    setSketchfabInstanceID(event.target.value);
  }
  const isShowChange = (event : any) => {
    setIsShow(event.target.checked);
  }

  return<div>
    <div>
      <label>
        Geometry ID:
        <input type={"text"} value={objectGUID} onChange={groupIdChange}/>
      </label>
    </div>
    <div>
      <label>
        Texture UID
        <input type={"text"} value={materialUID} onChange={materialUIDChange}/>
      </label>
    </div>
    <div>
      <label>
        Sketcfab InstaceID
        <input type={"number"} value={sketchfabInstanceID} onChange={sketchfabInstanceIDChange}/>
      </label>
    </div>
    <div>
      <label>
        Show:
        <input type={"checkbox"} checked={isShow} onChange={isShowChange}/>
      </label>
    </div>
    <div>
      <button onClick={applyClick}>Apply</button>
    </div>

    Graph
    <div className="debug">
      {/*nodeMap && SketchFabViewerUtils.getNodeMapStr(nodeMap)*/}
    </div>
    Materials
    <div className="debug">
      {false && materials && SketchFabViewerUtils.getMaterialsStr(materials)}
    </div>
    Textures
    <div className="debug">
      {false && textures && SketchFabViewerUtils.getTexturesStr(textures)}
    </div>
  </div>
}

export default DebugTools;