import React, {useState, useRef, useEffect} from "react";
import {ChangeCommand, IMapTableRecord, IModel} from "./App";
import styles from './SketchfabViewer.module.css'
import {
    ConstructNameToInstanceID, SingleView,
    SketchFabViewerUtils,
    ViewMetaData
} from "./SketchFabViewerUtils";
import {ISelectedOption} from "./OptionSelector";
import ViewSelector from "./ViewSelector";
import ConstructSelector from "./ConstructSelector";
import Cameras from "./Cameras";
import DebugTools from "./DebugTools";
import ViewerInitializer from "./ViewerInitializer";

export interface INode {
    children: INode[];
    instanceID: number;
    name: string;
    materialID: string;
}

export interface ICamera {
    name: string;
    origin: number[];
    target: number[];
}

export interface ISketchfabViewerApi{
    hide(i :number) : void;
    show(i :number) : void;
    start(): void;
    addEventListener(event: string, func: any): void;
    getSceneGraph(func: any) : void;
    getNodeMap(func: any) : void;
    getTextureList(func: any) : void;
    getMaterialList(func: any) : void;
    assignMaterial(myNode: any, myMaterialID: any, func: any) : void;
    setMaterial(material: any, func: any) : void;
    setCameraLookAt(position: number[], target: number[]) : void;
    setWireframe(enable: boolean,  func: any): void;
    highlightMaterial(material: any): void;
    highlightMaterial(): void;
    setHighlightOptions(options: any) : void;
}

interface ISketchFabViewerProps {
    changeCommands: ChangeCommand[] | undefined;
    model: IModel;
    mapTable: IMapTableRecord[] | undefined;
    //selectedOptions: ISelectedOption[] | undefined;
}

function moveCamera(camera: ICamera, api: ISketchfabViewerApi | undefined){
    if(api) {
        api.setCameraLookAt(camera.origin, camera.target)
    }
}


function ApplyCommand(changeCommand: ChangeCommand, api: any, nodeMap: any, guidToObjectIDMap : Map<string, number>){
    //get sketchfab objectID from guid
    let instanceID = changeCommand.sketchfabInstanceID ? changeCommand.sketchfabInstanceID : guidToObjectIDMap.get(changeCommand.objectGUID);

    /*//HACKY HACKH ASDKFASDFASFDSDHSNJDGDYJH
    if(!instanceID){
        instanceID = guidToObjectIDMap.get("1" + changeCommand.objectGUID.substring(1));
    }*/

    if(!instanceID){
        return;
    }

    changeCommand.isShow ? api.show(instanceID) : api.hide(instanceID);
    if(changeCommand.textureUID && changeCommand.textureUID.length > 0){
        let node = nodeMap[instanceID];
        //let materialID = node.materialID;
        //let material = materials.find((material : any) => material.id === materialID);
        api.assignMaterial(node, changeCommand.textureUID, function(err : any) {
            if (!err) {
                window.console.log('Material assigned');
            }
        });
    }
}

function applyCommands(changeCommands: ChangeCommand[], api: any, nodeMap: any, guidToObjectIDMap : Map<string, number>){
    changeCommands.forEach((command : ChangeCommand) => {
        ApplyCommand(command, api, nodeMap, guidToObjectIDMap);
    })
}

function SketchfabViewer(props: ISketchFabViewerProps) {
    const { changeCommands, model, mapTable } = props;

    const viewerapi = useRef<ISketchfabViewerApi | undefined>(undefined);
    const graph = useRef<object | undefined>(undefined);
    const [nodeMap, setNodeMap] = useState<any | undefined>(undefined);
    const [textures, setTextures] = useState<Map<any, any> | undefined>(undefined);
    const [materials, setMaterials] = useState<any[] | undefined>(undefined);
    const [guidToObjectIDMap, setGuidToObjectIDMap] = useState<Map<string, number> | undefined>(undefined);
    const [constructToInstanceIDMap, setConstructToInstanceIDMap] = useState<ConstructNameToInstanceID[] | undefined>(undefined);
    const [constructVisibilities, setConstructVisibilities] = useState<Map<string, boolean> | undefined>(undefined);

    const [highlightModeOn, setHighlightModeOn] = useState<boolean>(false);
    const highlightModeRef = useRef<boolean>(highlightModeOn);
    const viewerIframeRef = useRef<any>(null);


    const applyView = (view: SingleView) => {
        if (constructVisibilities && constructToInstanceIDMap) {

            let newConstructVisibilities = new Map<string, boolean>();

            constructVisibilities.forEach((value: boolean, key: string) => {
                let construct = constructToInstanceIDMap.find(c => c.name === key);
                if(view.constructs.indexOf(key) >= 0){
                    newConstructVisibilities.set(key, true);
                    SketchFabViewerUtils.showOrHideObjcects([construct!.id], viewerapi.current, true);
                }
                else{
                    newConstructVisibilities.set(key, false);
                    SketchFabViewerUtils.showOrHideObjcects([construct!.id], viewerapi.current, false);
                }
            });
            setConstructVisibilities(newConstructVisibilities);
        }
    }

    const toggleAllConstructs = (show: boolean) => {
        let newConstructVisibilities = new Map<string, boolean>();
        for(let construct of constructToInstanceIDMap!){
            SketchFabViewerUtils.showOrHideObjcects([construct.id], viewerapi.current, show);
            newConstructVisibilities.set(construct.name, show);
        }
        setConstructVisibilities(newConstructVisibilities);
    }
    const changeConstructVisibility = (construct: ConstructNameToInstanceID) => {
        if(constructVisibilities){
            let wasVisible = constructVisibilities.get(construct.name);

            let newConstructVisibilities = new Map(constructVisibilities);
            newConstructVisibilities.set(construct.name, !wasVisible);
            SketchFabViewerUtils.showOrHideObjcects([construct.id], viewerapi.current, !wasVisible);
            setConstructVisibilities(newConstructVisibilities);
        }
    }

    const nodeMouseEnter = (node: any) => {
        if(highlightModeRef.current && viewerapi.current && node && node.material){
            viewerapi.current.highlightMaterial(node.material);
        }
    }

    const showClickedConstruct = (instenceId: string) => {
        if(!graph.current || !highlightModeRef.current){
            return;
        }
        let path : string[] = [];
        SketchFabViewerUtils.findPathToNode(graph.current, instenceId, path);
        alert(path.join("\n"));
    }

    useEffect(() => {
        let initializer = new ViewerInitializer(model, viewerIframeRef, graph, setNodeMap, setTextures,
          setMaterials, viewerapi, showClickedConstruct, nodeMouseEnter, moveCamera);
        initializer.initializeSketchfabViewer();
    }, []);

    useEffect(() => {
        if(nodeMap && viewerapi.current && mapTable) {
            const {guidToInstanceIDMap, constructsToInstanceIDMap} = SketchFabViewerUtils.GetGuidToInstanceIDMap(nodeMap);

            setConstructToInstanceIDMap(constructsToInstanceIDMap);
            let constructVisibilities = new Map<string, boolean>();
            for(let constructNameToInstanceID of constructsToInstanceIDMap){
                constructVisibilities.set(constructNameToInstanceID.name, true);
            }
            setConstructVisibilities(constructVisibilities);


            setGuidToObjectIDMap(guidToInstanceIDMap);

            console.log(constructsToInstanceIDMap);
            console.log(guidToInstanceIDMap);

        }
    }, [nodeMap])

    useEffect(() => {

        if(viewerapi.current && nodeMap && changeCommands && guidToObjectIDMap) {
            applyCommands(changeCommands, viewerapi.current, nodeMap, guidToObjectIDMap);
        }
    }, [changeCommands, viewerapi.current, guidToObjectIDMap])

    return (
        <div className={styles.sketchfabViewer}>
            <header className={styles.sketchfabViewerHeader}>

                <div>
                    <iframe ref={viewerIframeRef} style={{width: "100%", height: "calc(100vh - 400px)"}}>
                    </iframe>
                </div>

                <div className={styles.camerasAndViews}>
                    {model.cameras && viewerapi.current &&
                      <Cameras cameras={model.cameras} viewerapi={viewerapi.current!} moveCamera={moveCamera}></Cameras>
                    }

                    <ViewSelector applyView={applyView}
                                  highlightModeOn={highlightModeOn}
                                  setHighlightModeOn={setHighlightModeOn}
                                  highlightModeRef={highlightModeRef} />
                </div>

                {constructVisibilities && viewerapi.current &&
                  <ConstructSelector  constructToInstanceIDMap={constructToInstanceIDMap}
                                      constructVisibilities={constructVisibilities!}
                                      changeConstructVisibility={changeConstructVisibility}
                                      toggleAllConstructs={toggleAllConstructs}

                  />}


                {false && viewerapi.current && guidToObjectIDMap &&
                  <DebugTools viewerapi={viewerapi.current!} textures={textures} materials={materials}
                              ApplyCommand={ApplyCommand} nodeMap={nodeMap} guidToObjectIDMap={guidToObjectIDMap!}/>
                }

            </header>
        </div>
    );
}


export default SketchfabViewer;