import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OptEngineWrapper, getOptEngine} from "@bimaire/optengine";
import {BrowserRouter} from "react-router-dom";
import theme from "./theme";
import {ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
getOptEngine().then(optionEngine => {
    root.render(
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App optionEngine={new OptEngineWrapper(optionEngine)}/>
            </BrowserRouter>
          </ThemeProvider>
        </React.StrictMode>
    );

})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
