import {ISelectedOption} from "./OptionSelector";
import {ChangeCommand, IMapTableRecord} from "./App";
import {OptEngineWrapper, ChoiceContextWrapper} from "@bimaire/optengine"
import {IJSProgram, IViewerGeometryItem} from "@bimaire/optengine/build/optengine_loader";

class OptionEvaluator{

    private readonly program: IJSProgram;
    constructor(private readonly optionEngine: OptEngineWrapper, private readonly mapTable: IMapTableRecord[]){
        const configItems = mapTable.map(item => {
            const retval: IViewerGeometryItem = {
                conditionString: item.OptionString,
                construct: "-",
                objectId: item.BoxGuid
            };
            return retval;
        })
        this.program = optionEngine.getProgram(configItems);
    }

    getChangeCommands(selectedOptions : ISelectedOption[]) : ChangeCommand[] {
        const ctxt = this.optionEngine.getChoiceContext();
        for (const opt of selectedOptions) {
            ctxt.addChoice([opt.optSelID, opt.optValID]);
        }
        const visibilities = ctxt.runProgram(this.program);
        const changeCommands = visibilities.map(vis => new ChangeCommand(vis.objectId, vis.isVisible, ""));
        return changeCommands;
    }
}


export {OptionEvaluator}